.device-docs-table {
  width: 100%;

  .device-img-dropzone {
    flex-wrap: nowrap;
    align-items: center;

    .p-fileupload-content {
      width: 100%;
      height: 3rem;
    }
  }

  .language-row {
    svg {
      height: 3rem;
      width: 3rem;
      color: #ea6053;
      margin: 1rem 0 1rem 1rem;
      cursor: pointer;
    }
  }

  .first-th-item {
    width: 100px;
  }

  .last-th-item {
    width: 60px;
  }

  th {
    width: 45%;
  }

  .p-fileupload .p-fileupload-content {
    padding: 5px;
  }

  .device-img-dropzone .p-fileupload-content {
    flex: 1 1 auto;
  }
}

.p-fileupload-row > div {
  padding: 0 5px;
  font-size: 12px;
}

body .p-dropdown {
  border-width: 2px;
  border-style: solid;
  border-color: $input-border-color;
  background: $input-bg-color;
  height: 3rem;
  border-radius: 3px;
  padding: 0 1rem !important;
  display: flex !important;
  align-items: center !important;
  box-shadow: 1px 1px 8px 3px rgba(11, 49, 75, 0.1);

  &.not-cleanable {
    .p-dropdown-clear-icon {
      display: none;
    }
  }

  .p-dropdown-trigger {
    width: auto;

    .p-dropdown-trigger-icon {
      margin-left: 0.5em;
      color: #d2d2d2;
    }
  }

  & .p-dropdown-trigger,
  .p-dropdown-clear-icon {
    color: white;
    font-size: 12px;
    background: transparent;
  }

  & .p-dropdown-label {
    border: none !important;
    padding: 0 !important;
    height: auto !important;
  }

  &:not(.p-disabled):hover {
    border-color: $input-border-color;
  }
}

.custom-input-phone-group {
  @import 'flags';
  $selected-width: 6em;

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &__selected {
    height: 100%;
    width: $selected-width;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 5px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .flag {
      padding: 0 6px 0 8px;
      margin-left: 5px;
    }

    .countrycode {
      margin-left: 7px;
    }

    .dropdown-arrow {
      margin-left: 6px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
    }
  }

  input {
    padding-left: calc(#{$selected-width} + 20px) !important;
  }

  $country-item-height: 35px;
  $country-item-selected-margin: 20px;

  .countries-select {
    position: absolute;
    top: 29px;
    width: 100%;
    height: calc(
      (#{$country-item-height} * 4) + #{$country-item-selected-margin}
    );
    padding: 5px 10px;
    background: #365162;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    display: flex;
    z-index: 999999;
    box-shadow: 4px 10px 64px -21px rgba(0, 0, 0, 0.66);

    &__item {
      padding: 5px;
      outline: 0;
      list-style: none;
      min-height: $country-item-height;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      .flag,
      .country-name {
        margin-right: 6px;
      }

      .country-name {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap;
      }

      .countrycode {
        color: #999;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &.selected {
        order: -1;
        position: relative;
        margin-bottom: $country-item-selected-margin;

        &::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          background-color: #999;
        }
      }
    }

    + .custom-input-phone-group__selected {
      .dropdown-arrow {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }
}

@import '../../../assets/styles/main-style/modules/colors';

.common-card-wrapper {
  padding: 25px 15px;
  color: white;
  width: 100%;
}

.user-profile-card {
  position: relative;
  width: 100%;
}

body .p-card {
  background: $body-bg;
  color: white;
}
.p-card-content {
  display: flex;
  flex-wrap: wrap;
  b {
    color: $active-item;
  }
}

.p-card-title {
  color: white;
  margin-bottom: 1rem;

  svg {
    margin-right: 13px;
    width: 25px;
    height: 25px;
    color: white;
  }
}
.promotion-content-wrapper {
  .p-card-title {
    color: $primary-color;
    margin-bottom: 1rem;

    svg {
      margin-right: 13px;
      width: 25px;
      height: 25px;
      color: white;
    }
  }
}

.p-card .p-card-body {
  padding: 0.3rem 0.8rem;
}
.p-card .p-card-content {
  padding: 0;
}

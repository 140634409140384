@import '~primeicons/primeicons.css';

body .p-calendar {
  cursor: pointer;
  align-items: center;

  & ~ label {
    margin-left: 10px;
  }

  .p-button-text-only {
    @extend .secondary-btn;
    display: inline;
  }
}

.p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
  width: 100% !important;
}

.p-calendar-w-btn {
  background: $input-bg-color;

  .pi-calendar:before {
    color: #d2d2d2;
  }

  .p-datepicker-trigger {
    .p-button-label {
      display: none;
    }
  }
}

.p-calendar .p-calendar-button {
  position: absolute !important;
  padding: 0 15px !important;
  top: 1px;
  right: 2px;
  height: 100% !important;
  display: flex !important;

  span {
    margin: 0 !important;
  }
}

body .p-datepicker {
  table {
    font-size: 13px;
  }

  table td > span {
    padding: 3px;

    &.p-disabled {
      opacity: 0.3;
    }
  }
}

.p-calendar .p-datepicker-touch-ui,
.p-datepicker-touch-ui {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 320px;
  transform: translate(-50%, -50%);
  max-height: 537px;

  .p-datepicker .p-timepicker button {
    width: 1.5rem;
    height: 1rem;
    color: hsla(0, 0%, 100%, 0.6);
    border: 0;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    transform: rotate(-90deg);
  }

  .p-timepicker > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

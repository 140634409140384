.img-preview {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

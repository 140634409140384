.common-device-headline {
  color: white;
  padding: 1rem 3rem;
  .device-category {
    font-size: 1.2rem;
  }
  .device-company,
  .device-model {
    font-size: 1.4rem;
    color: #9fa8da;
    margin: 0 15px;
  }
  .device-model {
  }
}

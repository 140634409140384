.change-status-group {
  display: flex;

  &__button {
    margin-right: 10px;
  }
}

.settings-button-block {
  .change-status-group {
    margin-left: 10px;

    &__btn {
      width: min-content;
    }
  }
}

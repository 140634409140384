body .p-message.p-message-error {
  background-color: $danger-elem !important;
}

body .p-message.p-message-error .p-message-icon {
  color: white !important;
  margin-right: 10px;
}

.p-inline-message.p-inline-message-error {
  background-color: $danger-elem !important;
  border: 0 none;
  color: white !important;
  padding: 8px 15px;
  justify-content: flex-start;
  width: 100%;
  margin-top: 5px;

  .p-inline-message-icon {
    color: white !important;
    margin-right: 10px;
  }
}

.share-item-wrapper {
  width: 100%;
  min-height: 100px;
  background: #304256;
  border-radius: 8px;
  margin: 6px 0;
  display: flex;
  flex-wrap: nowrap;
  padding: 8px;
  align-items: flex-end;

  .community-item-active-date {
  }

  .share-item-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .share-item-name {
      font-size: 16px;
      line-height: 120%;
      color: white;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 340px;
      margin: 12px 0;
    }

    .share-item-desc {
      font-size: 13px;
      line-height: 120%;
      color: #76859c;
      max-height: 30px;
      overflow: hidden;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      transition: 0.3s;
      opacity: 1;
      margin-bottom: 12px;
    }

    .share-item-devices {
      display: flex;
      flex-wrap: nowrap;

      .item-device {
        padding: 8px;
        background: #1d283a;
        border-radius: 4px;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #76859c;
        text-transform: uppercase;
        margin-right: 4px;
      }
    }
  }

  &:hover {
    background: #1f384c;
    .common-button {
      background: #18a0fb !important;
    }
  }
}

.community-item-active-date {
  display: flex;
  align-items: center;

  svg {
    color: white;
  }

  .active-date {
    color: white;
    font-size: 13px;
    margin-left: 10px;
  }

  svg {
    height: 19px;
    width: 19px;
  }
}

body #root .share-item-wrapper .common-button.p-button.p-component {
  height: 32px !important;
  border-radius: 6px !important;
  padding: 0 20px !important;
}

$body-bg: #2d3e50;
$action-bg-color: #0d273b;
$main-bg-color: #e5e5e5;
$modal-bg-color: #354b60;
$active-item: #9fa8da;

$active-menu-item: #c0a785;

$base-border-color: #dde2e5;

/*input*/

$label-color: #ffffff;
$input-bg-color: #304256;
$input-border-color: #32658c;

/*button*/

$btn-color: #385167;
$action-btn-color: #2a80b9;

$danger-elem: #ea6053;
$warn-elem: #7f6003;
$primary-color: #16b89e;

body .p-card.sign-card {
  max-width: 400px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  background: $modal-bg-color;
  border: 3px solid #475b6f;
  border-radius: 6px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);

  .sign-card-secondary-title {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 35px;
    color: $label-color;
  }

  .form-text {
    font-family: Open Sans, sans-serif;
    color: $label-color;
    margin: 0;
  }

  .sign-card-action-title {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: $label-color;
  }

  .p-card-body {
    padding: 0 !important;
    width: 100%;
    height: 100%;
  }
  .p-card .p-card-body {
    padding: 0.5rem 1rem;
  }

  .sign-link {
    width: 50%;
    .sign-in-button {
      width: 100% !important;
      box-shadow: -1px 0px 4px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px 0 0 3px !important;
      &:hover {
        box-shadow: -1px 0px 4px 2px rgba(0, 0, 0, 0.4);
      }
      &.active {
        background: $action-btn-color !important;
      }
    }

    .sign-up-button {
      width: 100% !important;
      box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.3);
      border-radius: 0 3px 3px 0 !important;
      &:hover {
        box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, 0.4);
      }
      &.active {
        background: $action-btn-color !important;
      }
    }
  }
}
.password-link-msg {
  margin: 0 auto;
  color: white !important;
}
.change-password {
  .sign-card-form-block {
    padding-top: 0;
  }
}

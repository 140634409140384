.community-profile-activity {
  padding: 0.5rem;
  position: absolute;
  right: 3rem;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  $size: 2rem;

  &__col {
    margin-left: 1rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .pi {
      font-size: 1.5rem;
      color: #16b89e;
    }

    .count {
      margin-left: 10px;
      font-size: 1.1rem;
      color: white;
    }
  }

  .lightning-effects-tag.p-tag {
    white-space: nowrap;
    font-size: 14px;
    color: white;
    height: 2rem;
    padding: 0 10px;
    margin-left: 2rem;
    background: #16b89e;
  }
}

.promotion-item-link {
  display: flex;
  align-items: center;

  a {
    color: #16b89e;
    font-size: 13px;
    text-align: right;
    margin-right: 10px;
  }

  i {
    color: #16b89e;
  }

  &--lg {
    a {
      font-size: 20px;
    }
  }
}

.btn.secondary-btn {
  white-space: nowrap;

  a,
  span {
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import '../modules/colors';

.common-btn,
.p-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: min-content !important;
  padding: 0 1.4rem !important;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  border-radius: 3px !important;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  color: $label-color;

  span:not(.p-origin-style) {
    white-space: nowrap;
    padding: 0 !important;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.p-disabled {
    filter: opacity(0.8);
    pointer-events: none;
  }
}

.link-btn,
.p-button-icon-only:not(.p-origin-style) {
  border: none !important;
  background: transparent !important;
  width: min-content;
  display: inline-block;
  cursor: pointer;
  height: 100%;
}

.common-button-rounded {
  border-radius: 50% !important;
  height: 30px !important;
  width: 30px !important;
  max-width: 30px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  .pi {
    color: white;
  }
}

.action-btn {
  @extend .common-btn;
  border-color: $btn-color !important;
  background: $btn-color !important;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.3);

  span {
    color: $label-color;
  }

  &:hover {
    box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, 0.4);
  }
}

.btn.secondary-btn,
.p-button-secondary {
  @extend .common-btn;
  border-color: $btn-color !important;
  background: $action-btn-color !important;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.3);

  span {
    color: $label-color;
    background: transparent;
  }

  &:hover {
    box-shadow: 1px 0px 4px 2px rgba(0, 0, 0, 0.4);
  }
}

.wide-btn {
  width: 100% !important;
}

.action-link-btn {
  @extend .link-btn;
  font-size: 1rem !important;
  font-weight: 600 !important;

  span,
  a {
    white-space: nowrap;
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.5rem;
    color: $label-color !important;
  }

  a {
    font-size: 1rem !important;
    font-weight: 600;
  }
}

.secondary-link-btn {
  @extend .link-btn;

  span {
    color: $label-color !important;
    font-size: 0.87rem !important;
    line-height: 1.4rem;
  }
}

/*Tertiary  button*/

.p-button.tertiary-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px !important;
  border: 1px solid transparent;
  white-space: nowrap;

  &:not(.confirm-tertiary) {
    height: 1.5rem;
    padding: 0 10px !important;
  }

  span,
  a {
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.44px;
    font-size: 0.8rem !important;
    margin: 0 !important;
    color: white;

    &.pi {
      margin: 0 5px !important;
    }
  }
}

.success-tertiary-button {
  background: $active-item !important;

  span,
  a {
    color: white !important;
  }

  &:hover {
    background: white !important;
    border: 1px solid $active-item;

    span,
    a {
      color: $active-item !important;
    }
  }
}

.danger-tertiary-button {
  background: $danger-elem !important;
  color: white !important;

  .pi,
  a {
    font-size: 1rem !important;
    line-height: 1rem !important;
    color: white !important;
  }

  &:hover {
    background: white !important;
    color: $danger-elem !important;
    border: 1px solid $danger-elem !important;

    .pi,
    a,
    span {
      color: $danger-elem !important;
    }
  }
}

.warn-tertiary-button {
  background: $warn-elem !important;
  color: white !important;

  .pi,
  a {
    font-size: 1rem !important;
    line-height: 1rem !important;
    color: white !important;
  }

  &:hover {
    background: white !important;
    color: $warn-elem !important;
    border: 1px solid $warn-elem !important;

    .pi,
    a,
    span {
      color: $warn-elem !important;
    }
  }
}

.settings-button-block {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.auth-networks-button {
  @extend .common-btn;
  background: white;
  width: 100% !important;
}

.google-btn {
  border: 1px solid #34a853;

  &:hover {
    background: rgba(52, 168, 83, 0.15);
  }
}

.facebook-btn {
  border: 1px solid #0369cc;

  &:hover {
    background: rgba(3, 105, 204, 0.15);
  }
}

.full-width-btn {
  width: 100% !important;
}

body .p-disabled,
body .p-component:disabled {
  opacity: 0.8;
  .p-dropdown-trigger,
  .p-dropdown-clear-icon {
    display: none;
  }
}

body .p-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 transparent !important;
}

.p-button.p-component.common-button-rounded.danger-round-button.p-button-icon-only {
  background: $danger-elem !important;
  color: white !important;
  border: 1px solid $danger-elem !important;

  .pi {
    font-size: 1rem !important;
    line-height: 1rem !important;
    color: white !important;
  }

  &:hover {
    background: white !important;
    color: $danger-elem !important;
    border: 1px solid $danger-elem !important;

    .pi {
      color: $danger-elem !important;
    }
  }
}

.p-button.p-component.common-button-rounded.success-round-button.p-button-icon-only {
  background: $active-item !important;

  span {
    color: white !important;
  }

  &:hover {
    background: white !important;
    border: 1px solid $active-item;

    span {
      color: $active-item !important;
    }
  }
}

.btn.btn--add {
  white-space: nowrap;
  position: absolute;
  right: 28px;
  top: 18px;
  color: #fff;

  a {
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

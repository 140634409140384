.device-config-item {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px -1px rgba(225, 225, 225, 0.3);
  border-radius: 6px;
  height: 13rem;
  margin: 1rem;
  padding: 0.7rem 0.5rem;

  .item-action {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.delete {
    svg {
      color: #ea6053;
      font-size: 14px;
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  &.select {
    cursor: pointer;
  }

  .device-config-item__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 2rem;
    font-size: 1.1rem;
    position: relative;

    .p-field-checkbox > label,
    .p-field-radiobutton > label {
      margin-bottom: 0;
    }

    .p-checkbox {
      width: 20px !important;
      height: 20px !important;

      .p-checkbox-box {
        width: 20px !important;
        height: 20px;

        .p-checkbox-icon {
          height: 20px;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }

  & .device-config-item__body {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    flex: 1 1 auto;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  & .device-config-item__footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 2rem;
  }
}

.configuration-item {
  width: 100%;
}

.settings-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 1.3rem;

  .common-settings-menu {
    width: min-content;
    min-width: 170px;
    height: 0;
    position: absolute;
    z-index: 2;
    right: -15px;
    top: 55px;
    padding: 0;
    background: #304256;
    border-radius: 10px;
    opacity: 0;
    transition: 0.2s;

    &:after {
      width: 15px;
      height: 15px;
      content: '';
      position: absolute;
      background: #304256;
      transform: rotate(45deg);
      right: 28px;
      top: -8px;
      opacity: 0;
    }
  }

  .settings-item {
    display: flex;
    flex-wrap: nowrap;
    height: 45px;
    padding: 0 5px 0 15px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background: #354b60;
    border-radius: 6px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      color: white;

      path {
        stroke: #ffffff;
      }
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .settings-item-label {
      font-size: 15px;
      color: white;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    &:hover {
      box-shadow: 0 0 4px 3px rgba(225, 225, 225, 0.3);
    }
  }

  .settings-btn {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    outline: none;
    transition: 0.2s;

    svg {
      color: white;
      width: 30px;
      height: 30px;
      cursor: pointer;
      outline: none;
    }
  }
}

body .settings-item .p-dropdown {
  height: 40px;
  border: none;
  background: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 100%;

  &:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 rgba(136, 233, 170, 0);
    border-color: transparent;
  }

  .p-dropdown-trigger-icon.pi-chevron-down {
    display: none;
  }
}

.user-profile-block {
  display: flex;
  flex-wrap: nowrap;
}

body .settings-item .p-inputtext {
  background: #354b60 !important;
  width: min-content;
}

body .p-dropdown-panel {
  padding: 0;
  border: 0 solid transparent;
  background-color: #354b60;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

body .settings-item .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: white !important;
}

.active.settings-wrapper {
  .common-settings-menu {
    height: min-content;
    box-shadow: 0 0 3px 4px rgba(225, 225, 225, 0.06);
    padding: 10px;
    opacity: 1;
    transition: 0.2s;

    &:after {
      opacity: 1;
    }
  }

  .settings-btn {
    svg {
      transform: rotate(180deg);
      transition: 0.2s;
    }
  }
}

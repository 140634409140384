@import 'modules/colors';
@import 'partials/buttons';
@import 'sign/signForm';
@import 'comonPages/common';
@import 'datatable/table';

.base-disabled {
  pointer-events: none;
  filter: opacity(0.5);
  background: #ccc;
}
.danger-icon {
  color: $danger-elem;
}

.active-icon {
  color: $active-item;
}

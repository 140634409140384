.description-wrapper {
  flex: 1 1 auto;
  .description-header {
    font-size: 1.4rem;
    font-weight: 800;
    color: white;
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  .description-content {
    padding: 2rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #ffffff;
    height: 100%;
    & .default__description-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.instructions-wrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2rem;
  .instructions-download-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    width: min-content !important;
    padding: 1rem 2rem !important;
    background: rgba(255, 255, 255, 0.18);

    cursor: pointer;
    position: relative;
    color: white;
    font-size: 1.2rem;
  }
}

body .p-button {
  color: black;

  &:active:after {
    opacity: 0;
  }

  &:enabled:hover {
    color: #0b314b;
  }

  &.button-auto-width {
    width: auto;
    margin-right: 10px;
  }
}

.p-fileupload-choose input[type='file'] {
  z-index: 999;
}

.p-button.p-fileupload-choose.p-component.p-button-text-icon-left {
  width: auto;
  margin-right: 10px;
}

@import '../../../../../../assets/styles/main-style/modules/colors';

.device-images-list {
  margin-bottom: 10px;

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .img-wrapper {
      position: relative;
      min-width: 50px;
      min-height: 130px;
      width: 100%;
    }
  }
}

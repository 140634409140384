.device-settings-item {
  background: #304256 !important;
  border-color: #32658c !important;
  .device-category {
    font-size: 19px;
    border-radius: 3px;
    padding: 0.6rem 1rem;
    width: 100%;
    display: inline-flex;
  }
}

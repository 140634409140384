.remove-link {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;

  .icon--remove {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .datatable.p-datatable .p-paginator {
    height: 65px;
    display: flex;
    align-items: flex-end;
    width: 100vw;
    background: white;
    padding-bottom: 10px;

    .p-paginator-pages .p-paginator-page,
    .p-paginator-last,
    .p-paginator-next,
    .p-paginator-prev,
    .p-paginator-first {
      height: 2rem;
      min-width: 2rem;
      border: 0 none;
      line-height: 21px;
    }

    &.p-paginator-bottom .p-paginator-current {
      font-size: 12px;
      line-height: 20px;
      color: #73869e;
      position: absolute;
      top: 7px;
    }
  }
  .datatable.p-dropdown.p-component.p-inputwrapper-filled {
    position: absolute;
    right: 35px;
    height: 30px !important;
    border: none;
    &label.p-dropdown-label.p-inputtext {
      width: 52px;
      height: 30px !important;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .datatable.p-datatable .p-datatable-tbody > tr > td {
    height: 60px;
  }
  body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    border-bottom: 1px solid #dde2e5;
  }
}

body .p-multiselect {
  border-width: 2px;
  border-style: solid;
  border-color: $input-border-color !important;
  background: $input-bg-color !important;
  height: 3rem;
  border-radius: 3px;
  padding: 0 1rem !important;
  display: flex !important;
  align-items: center !important;
  box-shadow: 1px 1px 8px 3px rgba(11, 49, 75, 0.1);

  .p-multiselect-label-container {
    .p-multiselect-label {
      border: none !important;
      height: 100% !important;
      padding: 0 !important;
      font-size: 0.875rem !important;
    }
  }

  .p-multiselect-panel {
    background: $input-bg-color !important;

    .p-multiselect-header {
      background-color: inherit !important;

      .p-multiselect-close {
        color: #ffffff !important;
      }
    }
  }

  &.not-cleanable {
    .p-multiselect-clear-icon {
      display: none;
    }
  }

  .p-multiselect-trigger {
    width: auto;

    .p-multiselect-trigger-icon {
      color: #d2d2d2;
    }
  }

  .p-multiselect-trigger,
  .p-multiselect-clear-icon {
    color: white;
    font-size: 12px;
    background: transparent;
  }

  &:not(.p-disabled):hover {
    border-color: $input-border-color;
  }
}

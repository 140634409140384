@import '../../assets/styles/main-style/partials/buttons';
@import '../../assets/styles/main-style/modules/colors';

.dropzone {
  min-height: 50px;
  background: transparent;
  border: 3px dashed $active-item;
  color: white;
  cursor: pointer;

  &__message {
    text-align: center;
  }

  &__list {
    padding: 0.571em 1em;

    &-item {
      list-style: none;
      margin-top: 5px;
      display: table-row;

      div {
        padding: 0 5px;
        font-size: 12px;
        display: table-cell;
        vertical-align: middle;
      }

      .file-name {
        white-space: nowrap;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .img-wrapper {
        img {
          width: 50px;
        }
      }
    }
  }

  &.single-mode {
    width: 250px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
}

.promotion-dropzone {
  .dropzone {
    min-height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.common-gallery-wrapper {
  flex: 1 1 auto;

  .p-galleria-content {
    height: 100%;

    .p-galleria-item-wrapper {
      height: 100%;
    }
  }

  img {
    max-height: 45vh;
    max-width: 360px;
  }
}

.p-galleria-thumbnail-container {
  background: transparent;
}
.p-galleria-item {
  padding-bottom: 2rem;
}
.alt-image-preview {
  position: absolute;
  bottom: 0;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 2px;
  color: wheat;
}

.promotion-view-page {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 0;
  height: 100vh;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s, width 0s 0.5s;

  .promotion-view-wrapper {
    height: 100%;
    width: 0;
    transition: 0.3s;
    max-width: 0;

    .promotion-view-header {
      height: 50px;
      position: relative;
      padding: 0 20px;

      .promotion-active-date {
        height: 100%;
        align-items: center;
        font-size: 1.2rem;
      }

      .pi-times {
        position: absolute;
        right: 20px;
        top: 15px;
        color: white;
        cursor: pointer;
        font-size: 20px;
      }
    }

    .common-promotion-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 70%;

      .promotion-view-media {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        height: inherit;

        img {
          height: 90%;
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }

      .promotion-view-content {
        padding: 1rem 3rem;
        font-size: 18px;
        color: white;
      }
    }

    .promotion-view-footer {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 5px 20px 5px 5px;

      svg {
        width: 22px;
        height: 22px;
        color: white;
        cursor: pointer;

        &:hover {
          color: #16b89e;
        }
      }
    }
  }

  &.active {
    width: 100%;
    z-index: 3000;
    transition: opacity 0.3s;
    opacity: 1;

    .promotion-view-wrapper {
      max-width: 90%;
      width: 1200px;
      height: 90%;
      background: #354b60;
      transition: 0.3s;
      border-radius: 12px;
      box-shadow: 0 0 3px 4px rgba(255, 255, 255, 0.2);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

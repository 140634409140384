.oauth-label {
  position: relative;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d273b;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 45%;
    height: 1px;
    background-color: #f8f9fa;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

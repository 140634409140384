.react-json-view {
  padding: 20px 15px;
  overflow: scroll;
  .variable-value {
    white-space: normal;
  }
  .string-value {
    white-space: normal;
  }
}

@import '../../../assets/styles/main-style/modules/colors';

.common-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -19999999;
  height: 100vh;
  width: 0;
  opacity: 0;
  background: rgba(13, 39, 59, 0.4);
  transition: width 0.2s 0.4s, opacity 0.4s, z-index 0s 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;

  .common-modal-content {
    height: 100%;
    max-height: 700px;
    display: flex;
    width: 1px;
    transition: width 0.3s linear;
    flex-direction: column;
    background: $modal-bg-color;
    border: 3px solid #475b6f;
    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgba(225, 225, 225, 0.3);

    .common-modal-header,
    .common-modal-body {
      opacity: 0;
      transition: opacity 0.2s;
      overflow: auto;
    }

    .common-modal-header {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 70px;
      padding: 0 40px 0;
      overflow: hidden;

      .p-card-title {
        margin: 0;

        span {
          height: min-content;
          margin: 0;
        }
      }

      .button-close-dialog {
        margin-left: auto;
        padding: 0 !important;
        font-size: 22px;
        height: 34px;
        position: absolute;
        right: 2rem;
        top: 25px;
        color: white;
        svg {
          color: white;
        }
      }
    }

    .common-modal-body {
      flex: 1 1 auto;
      margin: 20px 20px 50px;
      padding: 20px 16px;
    }
  }

  &.active {
    width: 100%;
    opacity: 1;
    z-index: 3000;
    transition: width 0s, opacity 0.4s;

    .common-modal-content {
      min-width: 320px;
      width: 50%;
      transition: width 0.3s;

      .modal-text-content {
        font-family: Open Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 2.2rem;
        color: #19191d;
      }

      .common-modal-header,
      .common-modal-body {
        position: relative;
        opacity: 1;
        transition: opacity 0.3s 0.2s;
      }
    }
  }

  &.modal--md {
    .common-modal-content {
      max-width: 600px;
      height: 450px;
    }

    .common-modal-body {
      .button-save-preview {
        width: 100px !important;
      }
    }
  }

  &.modal--lg {
    .common-modal-content {
      max-width: 1000px;
      height: 650px;
    }
  }
}

@media (max-width: 586px) {
  .common-modal-wrapper {
    position: fixed;
    top: 0;
    left: 40px;

    &.active {
      .common-modal-content {
        width: 100%;
        left: 0;
        transition: width 0.3s;
      }
    }
  }
}

body .common-profile-tab-wrapper {
  max-width: 950px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  background: $modal-bg-color;
  border: 3px solid #475b6f;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  &.p-tabview .p-tabview-panels {
    background: #365162;
    box-shadow: 0px 2px 5px -1px rgba(225, 225, 225, 0.3);
    border-radius: 0 0 6px 6px;
    overflow: auto;
    height: 100%;
  }

  &.p-tabview.p-tabview-top .p-tabview-nav .p-highlight.p-tabview-selected a {
    background: #304256 !important;
    box-shadow: 0px 2px 5px -1px rgba(225, 225, 225, 0.3) !important;
    border: none !important;
  }

  &.p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus,
  &.p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus,
  &.p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus,
  &.p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.common-profile-tab-wrapper {
  ul {
    .profile-tab-headline {
      display: flex;
      align-items: center;

      a {
        span {
          white-space: normal;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .common-profile-tab-wrapper {
    .p-tabview-nav {
      flex-direction: column;

      .profile-tab-headline {
        a {
          width: 100%;

          span {
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }
  }
}

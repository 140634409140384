.gaming-srt-device-cart {
  display: flex;
  flex-direction: column;

  .device-settings-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .icon-field-remove {
      color: #ea6053;
      cursor: pointer;
      font-size: 1.3rem;
      margin-left: 1rem;
    }

    .icon-field-add {
      color: #16b89e;
      cursor: pointer;
      font-size: 1.3rem;
      margin-left: 1rem;
    }
  }
}

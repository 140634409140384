body .p-inputtext {
  width: 100%;
  font-size: 1rem !important;
  height: 3rem;
  border-style: solid !important;
  background: $input-bg-color !important;
  border-color: $input-border-color !important;
  color: $label-color;
  border-radius: 3px;
  padding: 0.6rem 1rem !important;
  display: flex !important;
  align-items: center !important;

  &:focus {
    border-color: $btn-color;
  }

  &.full {
    width: 100%;
  }

  .p-dropdown-label {
    max-height: 30px;
  }
}

.verify-code-input {
  width: max-content !important;

  input {
    width: 45px !important;
    height: 55px !important;
  }
}

.two-factor-checkbox {
  display: flex;
  align-items: center;
}

.p-message {
  margin: 4px 0 !important;
}

label {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
  color: $label-color;
  display: inline-flex;
  align-items: center;

  &.p-d-none {
    display: none;
  }
}

.p-dropdown-items-wrapper {
  box-shadow: 0 0 4px 3px rgba(225, 225, 225, 0.1);
}

.p-dropdown-clearable .p-placeholder {
  text-transform: capitalize;
}

.p-inputtext::placeholder,
.p-dropdown-label.p-placeholder {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  color: #acb5bd !important;
}

body .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-color: #007ad9;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px $active-item !important;
  -webkit-text-fill-color: white !important;
  color: white !important;
}

body .p-checkbox {
  $size: 25px;

  width: $size;
  height: $size;
  display: inline-block;
  vertical-align: middle;
  margin: 0;

  .p-checkbox-box {
    width: inherit;
    height: inherit;
    text-align: center;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
}

.p-checkbox.p-checkbox-checked:not(.p-checkbox-disabled):hover {
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
}

.checkbox-wrapper {
  height: 3rem;
}

body .p-checkbox-label {
  margin: 0;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 22px;
  line-height: 32px;
  width: 35px;
  height: 35px;
}

.p-fluid .p-inputtextarea {
  height: 6rem;
  overflow: auto !important;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
  content: '\e909' !important;
  position: relative;
  border-right: none;
  border-bottom: none;
  top: 0;
}
.public-control-checkbox {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.custom-radio-button {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

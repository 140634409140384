@import 'main-style/modules/colors';

.p-dialog {
  &-titlebar {
    background-color: $body-bg !important;
    color: #fff !important;

    .p-dialog-titlebar-icons {
      button {
        color: #fff !important;
        opacity: 0.5;
        transition: 0.2s !important;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &-content {
    background-color: $modal-bg-color !important;
  }
}

.popup-width {
  width: 500px;

  @media only screen and (max-width: 600px) {
    width: 310px;
  }
}

.popup-statistics-export {
  width: 100%;
  padding: 0 1.5rem !important;

  .p-dialog-content {
    height: 100%;
    min-height: 350px;
  }
}

.popup-lg {
  height: 100%;
  width: 100%;
  padding: 0 1.5rem !important;

  .p-dialog-content {
    height: 100%;
  }
}

.custom-confirm {
  &.expanded {
    .p-dialog-content {
      position: relative;
      min-height: 160px;
      min-width: 310px;
      display: flex;
      align-items: flex-start;
      padding: 1rem;

      .p-confirm-dialog-message {
        margin-top: 5px;
      }

      .value-wrapper {
        position: absolute;
        width: 90%;
        left: 50%;
        top: 70px;
        transform: translateX(-50%);

        .p-inline-message-error {
          width: 100%;
        }
      }
    }

    &.with-error {
      .p-dialog-content {
        min-height: 190px;
      }
    }
  }
}

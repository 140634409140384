.oauth-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 100%;
  padding: 0 1.4rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 1px;
  box-sizing: border-box;
  background: #fff;

  &__label {
    white-space: nowrap;
    padding: 0 !important;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    background: transparent;
    margin-left: 5px;
  }

  &.google {
    border: 1px solid #34a853;
  }

  &.facebook {
    border: 1px solid #0369cc;
  }

  &:not([disabled]) {
    cursor: pointer;

    &.google:hover {
      background: rgba(52, 168, 83, 0.15);
    }

    &.facebook:hover {
      background: rgba(3, 105, 204, 0.15);
    }
  }
}

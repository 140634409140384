.page-actions-wrapper {
  position: absolute;
  right: 40px;
  top: 15px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .page-action {
    margin-right: 5px;
  }
}

.p-dialog {
  .p-dialog-header {
    background-color: #354b60;
    padding: 10px 1.3rem;

    .p-dialog-titlebar {
      span.p-dialog-title {
      }

      .p-dialog-titlebar-icons {
        span.p-dialog-titlebar-close-icon {
        }
      }
    }
  }

  .p-dialog-content {
    padding: 1.5rem;

    i.p-confirm-dialog-icon {
      color: #ea6053;
    }

    .p-confirm-dialog-message {
      margin-left: 10px;
    }
  }

  .p-dialog-footer {
    background-color: #354b60;
    display: flex;
    justify-content: space-between;

    button.p-confirm-dialog-reject {
      span.p-button-text {
      }
    }

    button.p-confirm-dialog-accept {
      span .p-button-text {
      }
    }
  }
}

.answer-modal-wrapper {
  min-width: 320px;
  width: 40vw;

  .p-dialog-content {
    padding: 10px;
    textarea {
      width: 100%;
      min-height: 180px;
      padding: 10px 15px;
      background: #304256;
      color: white;
    }
  }
}

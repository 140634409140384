.free-pagination-item {
  position: absolute;
  top: 5px;
  right: 25px;
}

.promotion-content-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;

  .common-promotion-body {
    padding: 1.2rem;
    flex: 1 1 auto;

    .grid-promotion {
      display: grid;
      grid-gap: 0;
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
      height: 100%;

      .promotion-item-wrapper {
        display: flex;
        flex: 1 1 400px;
        overflow: hidden;
        padding: 0.8rem;
        position: relative;
        max-width: 100%;
        max-height: 320px;
        min-height: 300px;

        .promotion-item {
          border-radius: 6px;
          box-shadow: 0 0 8px 1px rgba(255, 255, 255, 0.1);
          width: 100%;
          display: flex;
          flex-direction: column;

          &-media {
            height: 75%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;

            img {
              height: 90%;
            }

            iframe {
              width: 100%;
              height: 100%;
            }

            .promotion-active-date {
              font-size: 13px;
              padding: 5px 0 10px 0;
            }
          }

          &-info {
            //height: 30%;
            height: 39%;
            display: flex;
            flex-direction: column;
          }

          &-content {
            border-top: 1px solid rgba(255, 255, 255, 0.06);
            color: white;
            font-size: 15px;
            flex: 1 1 auto;
            padding: 5px 15px;
            overflow: hidden;
          }
        }

        .promotion-item-footer {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 5px 15px;

          svg {
            width: 22px;
            height: 22px;
            color: white;
            cursor: pointer;

            &:hover {
              color: #16b89e;
            }
          }
        }

        .settings-button-block {
          width: 100%;
          display: flex;
          padding: 5px 15px;
          justify-content: flex-start;
        }
      }
    }
  }
}

.archived-item-info {
  width: min-content;
  white-space: nowrap;
  padding: 5px 15px;
  border-radius: 3px;
  color: white;
  background: #16b89e;
}
